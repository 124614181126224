import { render, staticRenderFns } from "./endpoints.vue?vue&type=template&id=32b13324&scoped=true&"
import script from "./endpoints.vue?vue&type=script&lang=ts&"
export * from "./endpoints.vue?vue&type=script&lang=ts&"
import style0 from "./endpoints.vue?vue&type=style&index=0&id=32b13324&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b13324",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
